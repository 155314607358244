@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter.var.woff2') format('woff2');
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  font-weight: 400;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  vertical-align: baseline;
  line-height: 1;
  -webkit-print-color-adjust: exact;
}

html {
  font-size: 16px;
}

strong, strong > span {
  font-weight: 550;
  font-variation-settings: 'wght' 650, 'slnt' 0;
}

main {
  margin: 0 auto 0 auto;
  max-width: 996px;
  padding: 2rem;
}

main > header {
  margin-bottom: 2rem;
}

main > article > header {
  margin-bottom: 3rem;
}

main > article > * + * {
  margin-top: 2rem;
}

a {
  outline: 2px dashed transparent;
  transition: outline-color 0.2s ease-in-out;
}
a:focus {
  outline: 2px dashed rgb(255, 45, 85);
}
